<template>
  <a-layout class="com-Info">
    <div v-if="companyInfo.id>0" class="desc-rows">
      <div class="one-row">
        <div class="label">认证状态</div>
        <div class="value">
          <a-tag color="red" v-if="companyInfo.authFlag!=2">待认证</a-tag>
          <a-tag color="green" v-else>已认证</a-tag>
        </div>
      </div>
      <template v-if="companyInfo.authFlag!=2">
        <div class="one-row flex-end hint">
          扫码进入小程序，进入【我的】-【我的认证】-【企业认证】，提交信息！
        </div>
        <div class="one-row flex-end hint">
          <img src="/static/images/wx_qrcode.jpg" style="width:120px;height: 120px;"/>
        </div>
      </template>
      <div class="line"/>
      <div class="one-row">
        <div class="label">企业名称</div>
        <div class="value">{{ companyInfo.company }}</div>
      </div>
      <div class="one-row">
        <div class="label">门市地址</div>
        <div class="value">{{ companyInfo.address }}</div>
      </div>
      <div class="one-row">
        <div class="label">联系电话</div>
        <div class="value">{{ companyInfo.mobile }}</div>
      </div>
      <div class="one-row hint">
        提示：门市地址和业务电话，是会对外展示的
      </div>
      <div class="line"/>
      <div class="one-row">
        <div class="label">业务联系方式</div>
        <div class="value">
          <a-button size="small" type="primary" @click="addContact">增加业务微信</a-button>
        </div>
      </div>
      <div class="one-row hint">
        <a-table :columns="columns" :data-source="weixinList" size="small" :bordered="true" rowKey="id"
                 :pagination="false" style="width: 100%"
        >
          <template slot="index" slot-scope="text, record,index">
            {{ index + 1 }}
          </template>
          <template slot="operation" slot-scope="text, record,index">
            <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5"
                    @click="editContact(index)" title="修改"></a-icon>
            <a-icon type="delete" theme="twoTone" twoToneColor="#FF9900" style="margin-left:20px;"
                    @click="deleteContact(index)" title="删除"></a-icon>
          </template>
        </a-table>
      </div>
      <edit-contact-modal ref="editModal" @success="saveContact"></edit-contact-modal>
    </div>
  </a-layout>
</template>

<script>


import EditContactModal from "./EditContactModal";

export default {
  name: "ViewCompanyInfo",
  components: {EditContactModal},
  data() {
    return {
      companyInfo: {}
    }
  },
  computed: {
    columns() {
      return [{
        title: '序号',
        align: 'center',
        width: 50,
        scopedSlots: {customRender: 'index'},
      }, {
        title: '微信/手机号',
        align: 'center',
        dataIndex: 'weixin'
      }, {
        title: '说明',
        dataIndex: 'title',
      }, {
        title: '操作',
        align: 'center',
        width: 100,
        scopedSlots: {customRender: 'operation'},
      }];
    },
    weixinList: {
      get() {
        let contactJson = this.companyInfo.contactJson;
        if (!contactJson) {
          return []
        }
        return JSON.parse(contactJson);
      },
      set(val) {
        this.companyInfo.contactJson = JSON.stringify(val)
      }
    }
  },
  mounted() {
    this.getCompanyInfo();
  },
  methods: {
    async getCompanyInfo() {
      let res = await this.$get('company/info');
      this.companyInfo = res.data || {}
    },
    editContact(index) {
      this.$refs.editModal.showModal('edit', index, this.weixinList)
    },
    addContact() {
      this.$refs.editModal.showModal('add', 0, this.weixinList)
    },
    deleteContact(index) {
      const _this = this
      this.$confirm({
        title: "删除确认",
        content: "此操作将永久删除微信/手机号-【" + _this.weixinList[index].weixin + "】, 是否继续?",
        centered: true,
        onOk() {
          let weixinList = [].concat(_this.weixinList);
          weixinList.splice(index, 1);
          _this.saveContact(weixinList)
        },
      });
    },
    saveContact(weixinList) {
      this.$post("company/updateContact", {
        id: this.companyInfo.id,
        contact: JSON.stringify(weixinList)
      }).then(res => {
        this.weixinList = weixinList
        this.$message.success("操作成功")
      })
    }
  }
}
</script>


<style scoped lang="less">
.com-Info {
  background: #fff;
  padding: 0 10px 10px 10px;
}

.desc-rows {
  width: 600px;
  display: flex;
  flex-direction: column;

  .one-row {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100%;

    .label {

    }

    .value {
      flex: 1;
      text-align: right;
      font-size: 1.1em;
    }
  }

  .hint {
    padding: 0 20px 10px;
    text-align: right;
    color: #999;
    justify-content: flex-end;
    font-size: 0.9em;
  }

  .line {
    margin: 0 20px;
    color: #999;
    border-top: 0.5px solid #DDD;
  }

  .flex-end {
    justify-content: flex-end;
  }
}

</style>

